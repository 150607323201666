<template>
  <el-dialog
          :append-to-body="true"
          :visible.sync="dialog"
          title="查看客户经理"
          width="1000px"
  >
    <blockquote class="my-blockquote">客户经理信息</blockquote>
    <el-row>
      <el-col><span class="label">所属板块编码:</span> <span>{{ data.sectorCode }}</span></el-col>
    </el-row>
    <el-row>
      <el-col :span="8"><span class="label">用户名:</span> <span>{{ data.userName }}</span></el-col>
      <el-col :span="8"><span class="label">机构经理姓名: </span> <span>{{data.fullname}}</span></el-col>
      <el-col :span="8"><span class="label">员工编码: </span> <span>{{data.empNo}}</span></el-col>
    </el-row>
    <el-row>
      <el-col :span="8"><span class="label">所属部门:</span> <span>{{ data.dept}}</span></el-col>
      <el-col :span="8"><span class="label">上级ID: </span> <span>{{data.parentManagerId}}</span></el-col>
      <el-col :span="8"><span class="label">手机号码: </span> <span>{{data.phone}}</span></el-col>
    </el-row>
    <el-row>
      <el-col :span="8"><span class="label">公司邮箱: </span><span>{{ data.email }}</span></el-col>
      <el-col :span="8"><span  class="label">入职时间: </span><span>{{ data.signDate }}</span></el-col>
      <el-col :span="8"><span class="label">就职状态: </span><span>{{ parseEmpStatus(data.empStatus) }}</span></el-col>
    </el-row>
    <el-row>
      <el-col><span class="label">客户注册链接: </span><span>{{ data.linkForCust }}</span></el-col>
    </el-row>
    <el-row
      v-for="(item, index) in links"
      :key="index"
    >
      <el-col><span class="label">{{item.linkName}} </span><span>{{ item.link }}</span></el-col>
    </el-row>

  </el-dialog>
</template>

<script>
import { parseTime } from '@/utils/index'
import checkPermission from '@/utils/permission'
import { queryManagerLink } from '@/api/ib/tManager'
export default {
  props: {
    empStatusoptions: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      default: null
    }
  },
  watch:{
    dialog(newVal){
      if(newVal){
        if(this.data.id){
          this.getLinks(this.data.id);
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      dialog: false ,
      events: null,
      auditContent:'',
      links: []
    }
  },
  methods: {
    parseTime,
    checkPermission,
    cancel(){
      this.dialog=false
      this.loading = false
    },
    parseEmpStatus(empStatus){
      for (var i = this.empStatusoptions.length - 1; i >= 0; i--) {
        if(this.empStatusoptions[i].key==empStatus){
          return this.empStatusoptions[i].displayName
        }
      }
      return empStatus
    },
    getLinks(managerId){
      queryManagerLink({
        managerId: managerId
      }).then((res) =>{
         this.links = res;
      })
    }
  }
}
</script>

<style scoped>
.el-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0;
  }
.el-col {
    border-radius: 4px;
  }
.label {
    font-size: 18px;
    padding: 10px 10px;
  }
</style>
