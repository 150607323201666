<template>
  <el-dialog
    :append-to-body="true"
    v-if="dialog"
    :visible.sync="dialog"
    :title="isAdd ? '新增经纪人' : '编辑经纪人'"
    width="500px"
  >
    <el-form
      ref="form"
      :model="form"
      size="small"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item
        label="所属板块"
        prop="sectorCode"
      >
        <el-select
            v-model="form.sectorCode"
            placeholder="请选择所属板块"
        >
          <el-option
            v-for="item in sectorList"
            :key="item.key"
            :label="item.displayName"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          label="开户链接"
          prop="teamId"
      >
        <el-select
            v-model="form.teamId"
            placeholder="请选择开户链接">
          <el-option
              v-for="(item,index) in teamList"
              :key="index"
              :label="item.teamName"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户名" prop="userName">
        <el-input
          v-model="form.userName"
          style="width: 300px;"
          :disabled="!isAdd"
        />
      </el-form-item>
      <el-form-item label="经纪人姓名" prop="fullname">
        <el-input
          v-model="form.fullname"
          style="width: 300px;"
          :disabled="!isAdd"
        />
      </el-form-item>
      <el-form-item label="员工编码" prop="empNo">
        <el-input
          v-model="form.empNo"
          style="width: 300px;"
          :disabled="!isAdd"
        />
      </el-form-item>
      <el-form-item label="所属部门" prop="deptId">
        <el-select
          v-model="form.deptId"
          placeholder="请选择所属部门"
        >
          <el-option
            v-for="item in deptList"
            :key="item.name"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
              label="职位"
              prop="position"
      >
        <el-select
          v-model="form.position"
          placeholder="请选择职位"
          clearable
        >
          <el-option
            v-for="(item,index) in positionList"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="上级姓名">
        <el-select
            v-model="form.parentManagerId"
            filterable
            placeholder="请输入上级姓名查找"
            style="width: 300px;"
            clearable
          >
            <el-option
              v-for="item in cusOptions"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input
          v-model="form.phone"
          style="width: 300px;"
          placeholder="选填"
        />
      </el-form-item>
      <el-form-item label="公司邮箱" prop="email">
        <el-input
          v-model="form.email"
          style="width: 300px;"
        />
      </el-form-item>
      <el-form-item label="TG群号" prop="telegramGroupId">
        <el-input
          v-model="form.telegramGroupId"
          style="width: 300px;"
        />
      </el-form-item>
      <el-form-item label="Lark群号" prop="larkGroupId">
        <el-input
            v-model="form.larkGroupId"
            style="width: 300px;"
        />
      </el-form-item>
      <el-form-item label="个人邮箱">
        <el-input
          v-model="form.personalEmail"
          style="width: 300px;"
          placeholder="选填"
        />
      </el-form-item>
      <el-form-item label="入职时间" prop="signDate">
        <el-date-picker
          v-model="form.signDate"
          align="right"
          type="date"
          placeholder="选择入职日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="就职状态" prop="empStatus">
        <el-select v-model="form.empStatus" placeholder="请选择就职状态">
        <el-option
          v-for="item in empStatusoptions"
          :key="item.key"
          :label="item.displayName"
          :value="item.key"
        >
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.empStatus == 2"
        label="离职日期"
      >
        <el-date-picker
          v-model="form.quitTime"
          align="right"
          type="date"
          placeholder="选择入职日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { strongUserName } from '@/utils/validate'
import { add, edit, saveManager,queryTeamInfo } from '@/api/ib/tManager'
export default {
  props: {
    empStatusoptions: {
      type: Array,
      required: true
    },
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    deptList: {
      type: Array,
      default(){
        return [];
      }
    },
    cusOptions: {
      type: Array,
      default(){
        return [];
      }
    },
    positionList:{
      type: Array,
      default(){
        return [];
      }
    },
    teamList: {
      type: Array,
      default(){
        return [];
      }
    }
  },
  data() {
    let validateUsername = (rule,value,callback) => {
      if(!value){
        callback(new Error('请输入用户名'));
      }
      if(!strongUserName(value)) {
        callback(new Error('用户名只能是字母、数字和下划线的组合'));
      }else {
        callback();
      }
    };
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      cusLoading:false,
      sectorList: [{
        key: 'FX',
        displayName: 'FX'
      }],
      loading: false, dialog: false,

      form: {
        id: '',
        sectorCode: '',
        teamId: '',
        userName: '',
        fullname: '',
        empNo: '',
        deptId: '',
        position: '',
        parentManagerId: '',
        phone: '',
        email: '',
        telegramGroupId: '',
        larkGroupId: '',
        signDate: '',
        empStatus: '',
        linkForIb: '',
        qrForIb: '',
        linkForCust: '',
        qrForCust: '',
        createTime: '',
        updateTime: '',
        quitTime: '',
      },
      rules:{
        sectorCode: [
          {required: true, message: '请选择所属板块', trigger: 'change' }
        ],
        userName: [
          {required: true,message: '请输入用户名', trigger: 'blur' },
          {validator: validateUsername, trigger: 'blur' },
        ],
        fullname:[
          {required: true, message: '请输入机构经理姓名', trigger: 'blur' }
        ],
        empNo:[
          {required: true, message: '员工编码', trigger: 'blur' }
        ],
        deptId:[
          {required: true, message: '请选择所属部门', trigger: 'change' }
        ],
        position: [
          {required: true, message: '请选择职位', trigger: 'change' }
        ],
        email:[
          {required: true, message: '请输入公司邮箱', trigger: 'blur' }
        ],
        // telegramGroupId:[
        //   {required: true, message: '请输入TG群号', trigger: 'blur' }
        // ],
        signDate:[
          {required: true, message: '请选择入职时间', trigger: 'change' }
        ],
        empStatus:[
          {required: true, message: '请选择就职状态', trigger: 'change' }
        ],

      },
    }
  },
  methods: {
    getAllTeamInfo(){
      queryTeamInfo().then((res) => {
        this.teamList = res;
      })
    },
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else this.doEdit()
        }else {
          console.log('no pass')
        }
      })
    },
    doAdd() {
      add(this.form).then(res => {
        saveManager({userName: this.form.userName}).then((res) => {
        })
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        sectorCode: '',
        teamId: '',
        userName: '',
        fullname: '',
        empNo: '',
        deptId: '',
        parentManagerId: '',
        phone: '',
        email: '',
        telegramGroupId: '',
        larkGroupId: '',
        signDate: '',
        empStatus: '',
        linkForIb: '',
        qrForIb: '',
        linkForCust: '',
        qrForCust: '',
        createTime: '',
        updateTime: '',
        departureDate: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
