<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm
      ref="form"
      :sup_this="sup_this"
      :is-add="false"
      :empStatusoptions="empStatusoptions"
      :dept-list="deptList"
      :cus-options="cusOptions"
      :position-list="positionList"
      :team-list="teamList"
    />
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    empStatusoptions: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    deptList: {
      type: Array,
      default(){
        return [];
      }
    },
    cusOptions:{
      type: Array,
      default(){
        return [];
      }
    },
    teamList: {
      type: Array,
      default(){
        return [];
      }
    },
    positionList:{
      type: Array,
      default(){
        return [];
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.empStatusoptions=this.empStatusoptions
      _this.form = {
        id: this.data.id,
        sectorCode: this.data.sectorCode,
        teamId: this.data.teamId,
        userName: this.data.userName,
        fullname: this.data.fullname,
        empNo: this.data.empNo,
        deptId: this.data.deptId,
        position: this.data.position + '',
        parentManagerId: this.data.parentManagerId || '',
        phone: this.data.phone,
        email: this.data.email,
        telegramGroupId: this.data.telegramGroupId,
        larkGroupId: this.data.larkGroupId,
        personalEmail: this.data.personalEmail,
        signDate: this.data.signDate,
        empStatus: this.data.empStatus,
        quitTime: this.data.quitTime,
      }
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
