<template>
  <div class="app-container">
    <eHeader
      :query="query"
      :empStatusoptions="empStatusoptions"
      :dept-list="deptList"
      :cus-options="cusOptions"
      :team-list="teamList"
      :position-list="positionList"
      @toQuery="toQuery"
      @clear="clear"
    />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="ID"/>
      <el-table-column
          v-if="name == 'admin'"
          label="开户链接"
      >
        <template slot-scope="scope">
          <span>{{getTeam(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="用户名"/>
      <el-table-column prop="fullname" label="经纪人姓名"/>
      <el-table-column
          v-if="name == 'admin'"
          prop="empNo" label="员工编码"/>
      <el-table-column
          v-if="name == 'admin'"
          prop="dept" label="所属部门"/>
      <el-table-column label="职位">
        <template slot-scope="scope">
          <span>{{getPosition(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="parentManagerName" label="上级姓名"/><!-- 需显示上级名称 -->
      <el-table-column prop="phone" label="手机号码"/>
      <el-table-column prop="personalEmail" label="个人邮箱"/>
      <el-table-column prop="email" label="公司邮箱"/>
      <el-table-column prop="signDate" label="入职时间"/>
      <!-- <el-table-column prop="empStatus" label="就职状态"/> -->
      <el-table-column prop="empStatus" label="就职状态">
        <template slot-scope="scope">
          <span>{{ parseEmpStatus(scope.row.empStatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="quitTime" label="离职时间"/>
      <el-table-column label="操作" width="150px" align="center">
        <template slot-scope="scope">
          <el-button
                  class="my-button margin-right5"
                  size="mini"
                  type="success"
                  v-if="checkPermission(['ADMIN','MANAGER_ALL','MANAGER_VIEW'])"
                  @click="lookHandle(scope.row)"
          >查看</el-button>
          <edit
            v-if="checkPermission(['ADMIN','MANAGER_ALL','MANAGER_EDIT'])"
            :data="scope.row"
            :sup_this="sup_this"
            :empStatusoptions="empStatusoptions"
            :dept-list="deptList"
            :position-list="positionList"
            :cus-options="cusOptions"
            :team-list="teamList"
          />
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <view-detail
            ref="viewdetail"
            :data="currentRow"
            :empStatusoptions="empStatusoptions"
    />
  </div>
</template>

<script>
import { getDict } from '@/api/system/dict'
import { mapGetters } from 'vuex'
import { initData } from "@/api/data";
import { queryDeptList } from '@/api/ib/tManager'
import checkPermission from '@/utils/permission'
import initDataList from '@/mixins/initData'
import { del,queryTeamInfo } from '@/api/ib/tManager'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/manager/managerlst/header'
import edit from '@/components/manager/managerlst/edit'
import viewDetail from '@/components/manager/managerlst/detail'
export default {
  name:'managerlst',
  components: { eHeader, edit, viewDetail },
  mixins: [initDataList],
  data() {
    return {
      empStatusoptions: [{
        key: 1,
        displayName: '在职'
      }, {
        key: 2,
        displayName: '离职'
      }, {
        key: 9,
        displayName: '其它'
      }],
      delLoading: false,
      sup_this: this,
      deptList: [],
      cusOptions:[],
      currentRow:{},
      viewDialogShow: false,

      teamList: [],

      positionList: [], //
    }
  },
  created() {
    this.getPositionList();
    this.getAllTeamInfo();
    this.getManager();
    this.getDeptList();
    this.$nextTick(() => {
      this.init()
    })
  },
  computed: {
    ...mapGetters(['name']),
  },
  methods: {
    getPosition(row){
      for (let i = 0; i < this.positionList.length; i++) {
        if(row.position == this.positionList[i].value){
          return this.positionList[i].label;
        }
      }
    },
    parseTime,
    checkPermission,
    getPositionList(){
      getDict({
        type: 'managerPosition'
      }).then((res) => {
        this.positionList = res.content;
      });
    },
    getTeam(row){
      let label = '';
      for (let i = 0; i < this.teamList.length; i++) {
        if(this.teamList[i].id == row.teamId){
          label = this.teamList[i].teamName;
          break;
        }
      }
      return label;
    },
    getAllTeamInfo(){
      queryTeamInfo().then((res) => {
        this.teamList = res;
      })
    },
    getManager() {
      const url = "crm/tManager";
      const params = {
        page: 0,
        size: 1000
      };
      initData(url, params)
        .then(res => {
          this.cusOptions = res.content;
        })
        .catch(err => {
        });
    },
    getDeptList(){
      queryDeptList({
        page: 0,
        size: 100,
      }).then((res) => {
        this.deptList = res.content;
      })
    },
    beforeInit() {
      this.url = 'crm/tManager';
      const sort = 'signDate,desc';
      this.params = { page: this.page, size: this.size, sort: sort };
      const query = this.query;
      const fullname = query.fullname;
      const parentManagerId = query.parentManagerId;
      const empStatus = query.empStatus;
      if (fullname !== '' && fullname !== null) {
        this.params['fullname'] = fullname;
      }
      if (parentManagerId !== '' && parentManagerId !== null) {
        this.params['parentManagerId'] = parentManagerId;
      };
      if (empStatus !== '' && empStatus !== null) {
        this.params['empStatus'] = empStatus;
      }
      if (this.query.deptId !== '' && this.query.deptId !== null) {
        this.params['deptInfo'] = this.query.deptId;
      }
      if(this.query.position){
        this.params['position'] = this.query.position;
      }
      return true
    },

    parseEmpStatus(empStatus){
      for (var i = this.empStatusoptions.length - 1; i >= 0; i--) {
        if(this.empStatusoptions[i].key==empStatus){
          return this.empStatusoptions[i].displayName
        }
      }
      return empStatus
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    },
    clear(){
      this.query = {};
      this.init();
    },
    lookHandle(row){
      this.currentRow = row;
      this.$refs.viewdetail.dialog = true;
    }
  }
}
</script>

<style scoped>

</style>
