/**
 * Created by jiachenpan on 16/11/18.
 */

/* 合法uri*/
export function validateURL(textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return urlregex.test(textval)
}

/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/* 大小写字母*/
export function validatAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

//只能输入由数字、26个英文字母或者下划线组成的字符串："^\w+$"
export function strongUserName(s)
{
  // var patrn = /^(?=.*\d)(?=.*[a-z]).{3,20}$/;
  var patrn = /^\w+$/;
  if (!patrn.exec(s)) return false;
  return true
}

/**
 * 验证邮箱
 * @param str
 * @returns {boolean}
 */
export function validatEmail(str) {
  const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
  return reg.test(str)
}
 //正数（包括小数)
/**
 * 验证正数（包括小数)
 * @param str
 * @returns {boolean}
 */
export function validatPositive (str) {
  const reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
  return reg.test(str);
}

/*非0正整数*/
export function noZeroInit(s) {
  var patrn = /^[1-9]\d*$/;
  if(!patrn.test(s)){ return false };
  return true;
}
// 正整数
/**
* 验证正整数（包含0)
* @param str
* @returns {boolean}
*/
export function validatpositiveInt (str) {
  const reg = /^[1-9]\d*$/;
  return reg.test(str);
}
// 0到100正数
/**
* 验证正整数（包含小数点)
* @param str
* @returns {boolean}
*/
export function validatohundred (str) {
  const reg = /^(0|\d{1,2}|100)(\.\d+)?$/;
  return reg.test(str);
}
// 大于零正数
/**
* 验证正整数（包含小数点)
* @param str
* @returns {boolean}
*/
export function validatgreater (str) {
  const reg = /^[1-9]\d*(\.\d+)?$/;
  return reg.test(str);
}

export function getByteLen(val) {
  var len = 0;
  for (var i = 0; i < val.length; i++) {
    var a = val.charAt(i);
    if (a.match(/[^\x00-\xff]/ig) != null) {//\x00-\xff→GBK双字节编码范围
      len += 2;
    }
    else {
      len += 1;
    }
  }
  return len;
}

export function limitEnAndSpace(string) {
  return string.replace(/[^\w\.\s\/]/ig,'');
}

export function limitNumber(string) {
  return string.replace(/\D/ig,'');
}

// 最多两位小数
export function twoDecimal(s) {
  var patrn = /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/;
  if(!patrn.test(s)){return false}
  if(s == 0){return false}
  return true;
}

// 支持两位小数
export function maxTwoFloatOrNone(s) {
  var patrn = /^\d+(?:\.\d{1,2})?$/;
  if(!patrn.test(s)) return false;
  return true;
}

// 支持一位小数
export function maxOneFloatOrNone(s) {
  var patrn = /^\d+(?:\.\d{0,1})?$/;
  if(!patrn.test(s)) return false;
  return true;
}

/*
* 是否包含负号
* */
export function isIncludeMinus(str) {
  return str.includes('-');
}



export function onlyTwoBitFloat(s) {
  var patrn = /^(-)?(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/;
  if(!patrn.test(s)) return false;
  return true;
}

/*金额输入框输入*/
export function amountInputValidate(val,managedFund){
  let value;
  val = val || '';
  if(managedFund){
    // 管理资金,10的倍数
    if(val.toString().match(/^\d*/g)){
      value = val.toString().match(/^\d*/g)[0];
    }
  }else {
    if(val.toString().match(/^\d*(\.?\d{0,2})/g)){
      value = val.toString().match(/^\d*(\.?\d{0,2})/g)[0];
    }
  }


  let amount = parseFloat(value)
  if (value === '' || value.endsWith('.') || value.endsWith('.0')) {
    amount = value
  }
  return amount;
}

